import axios, {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import Stores from "../stores";
import localforage from "localforage";
export const BASE_SERVER_URL = "https://api.wzjx.top";
export module APIHelper {
    const axiosInstance = axios.create({
        baseURL: BASE_SERVER_URL,
        // baseURL: "/api",
    })
    axiosInstance.interceptors.request.use(async (config) => {
        var UserToken = await localforage.getItem("User_Token");
        config.headers["Authorization"] = "Bearer " + (UserToken)
        config.headers["server"] = Stores.config.currentServer?.id;
        config.headers["player"] = Stores.player.playerId;
        return Promise.resolve(config);
    }, (error) => {
        return Promise.reject(error);
    })

    axiosInstance.interceptors.response.use((res) => {
        if (res.data.code !== 10000) {
            return Promise.reject(res.data.message)
        }
        return res;
    }, async (error: AxiosError) => {
        console.log(error.response.status);
        if (error.response.status == 401) {
            await localforage.removeItem("User_Token");
            window.location.hash = "/login"
            return Promise.reject("登录过期")
        }
        // @ts-ignore
        return Promise.reject(error.response.data.message)
    })

    /**
     * 获取系统配置项
     * @constructor
     */
    export function GetGameConfigs() {
        return axiosInstance.get("/configs").then((res) => {
            return res.data.data;
        })
    }


    export function RegisterUser(data: { username: string, password: string }) {
        return axiosInstance.post("/users/register", data).then((res) => {
            return res.data;
        })
    }

    export function Login(data: { username: string, password: string }) {
        return axiosInstance.post("/login", data).then(async (res) => {
            // @ts-ignore
            await localforage.setItem("User_Token", res.data.data.token);
            return res.data;
        })
    }


    export function UserInfo() {
        return axiosInstance.get("/users/info").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }


    export function ServerList() {
        return axiosInstance.get("/configs/servers").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data.data;
        })
    }

    export function ItemTemplateList() {
        return axiosInstance.get("/configs/items").then((res) => {
            // @ts-ignore
            return res.data.data;
        })
    }


    export function PlayerInfo(serverId: string) {
        return axiosInstance.get("/player").then((res) => {
            // @ts-ignore
            return res.data.data;
        })
    }

    export function GetPlayerByServer(serverId: string) {
        return axiosInstance.get("/player/find", {}).then((res) => {
            // @ts-ignore
            return res.data.data;
        })
    }

    export function CreateNewPlayer(data: { schoolId: string, serverId: string, name: string }) {
        return axiosInstance.post("/player", data).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function CraftNewItem(data: { itemId: string, amount: number }) {
        return axiosInstance.post("/player/craft", data).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function ExchangeItem(data: { itemId: string, amount: number }) {
        return axiosInstance.post("/player/exchange", {...data, amount: 1}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function ListPlayerItem() {
        return axiosInstance.get("/player/items").then((res) => {
            // @ts-ignore
            return res.data;
        })
    }

    export function PlayerTryUpgrade() {
        return axiosInstance.get("/player/upgradeLevel").then((res) => {
            // @ts-ignore
            return res.data;
        })
    }

    export function ListPlayerEquippedItem() {
        return axiosInstance.get("/player/equipped").then((res) => {
            // @ts-ignore
            return res.data;
        })
    }

    export function ListPlayerDailyTask() {
        return axiosInstance.get("/player/tasks").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function GetItemUpgradeInfo(itemId: string) {
        return axiosInstance.get("/player/upgrade?itemId=" + itemId).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function UpgradeItem(itemId: string) {
        return axiosInstance.post("/player/upgrade", {
            itemId: itemId
        }).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function DestroyItem(itemId: string) {
        return axiosInstance.post("/player/destroyItem", {
            itemId: itemId
        }).then((res) => {
            // @ts-ignore
            return res.data.data;
        })
    }


    export function DoBattle(data: { mapId: string }) {
        return axiosInstance.post("/player/battle", data).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function UnloadItem(data: { itemId: string }) {
        return axiosInstance.post("/player/unloadItem", data).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function LoadItem(data: { itemId: string }) {
        return axiosInstance.post("/player/loadItem", data).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function GetIdleInfo() {
        return axiosInstance.get("/player/idle").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function StartIdle(data: { mapId: string }) {
        return axiosInstance.post("/player/startIdle", data).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function EndIdle(data: { mapId: string }) {
        return axiosInstance.post("/player/endIdle", data).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function UpdatePlayerAbilityPoint(type: "1" | "2" | "3", amount: number) {
        return axiosInstance.post(`/player/ability`, {
            type: type,
            amount: amount
        }).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function GetMapBossInfo(map: string) {
        return axiosInstance.get("/player/mapBoss?mapId=" + map).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }


    export function GetDungeonInfo() {
        return axiosInstance.get("/player/dungeon").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function StartDungeon(map: string) {
        return axiosInstance.post("/player/startDungeon", {
            map: map
        }).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function ClaimReward(reward: string) {
        return axiosInstance.post("/player/claim", {
            reward: reward
        }).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }


    export function BattleDungeon(dungeon: string) {
        return axiosInstance.post("/player/dungeon", {
            dungeon: dungeon
        }).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function GetPlayerTerritory() {
        return axiosInstance.get("/player/territory").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function GetTerritoryList() {
        return axiosInstance.get("/player/territoryList").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }


    export function BattleTerritory(territory: string) {
        return axiosInstance.post("/player/battleTerritory", {territory: territory}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function ClaimTerritory(territory: string) {
        return axiosInstance.post("/player/claimTerritory", {territory: territory}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }


    export function GetSongjinList() {
        return axiosInstance.get("/player/songjinList").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function SignSongjin(songjin, team) {
        return axiosInstance.post("/player/songjinSign", {songjin: songjin, team: team}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function StatSongjin(songjin) {
        return axiosInstance.post("/player/songjinStat", {songjin: songjin}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function BattleSongjin(songjin: string) {
        return axiosInstance.post("/player/battleSongjin", {songjin: songjin}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function SongjinDetails(songjin) {
        return axiosInstance.get(`/player/songjin/${songjin}`).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function ListSongjinRank(songjin) {
        return axiosInstance.get(`/player/songjin/rank/${songjin}`).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function SongjinRankClaim(songjin) {
        return axiosInstance.get(`/player/songjin/claim/${songjin}`).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }


    export function GetMenpaiList() {
        return axiosInstance.get("/player/menpaiList").then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function SignMenpai(menpai) {
        return axiosInstance.post("/player/menpaiSign", {menpai: menpai}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function StatMenpai(menpai) {
        return axiosInstance.post("/player/menpaiStat", {menpai: menpai}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function BattleMenpai(menpai: string) {
        return axiosInstance.post("/player/battleMenpai", {menpai: menpai}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function MenpaiDetails(menpai) {
        return axiosInstance.get(`/player/menpai/${menpai}`).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }


    export function ListMenpaiRank(menpai) {
        return axiosInstance.get(`/player/menpai/rank/${menpai}`).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function ClaimMenpaiRank(menpai) {
        return axiosInstance.get(`/player/menpai/claim/${menpai}`).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function ListProduct() {
        return axiosInstance.get(`/pay/list`).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function CreatePayUrl(sku: string, payType: "alipay" | "wxpay") {
        return axiosInstance.get(`/pay?sku=${sku}&payType=${payType}`).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }

    export function SendMessage(msg: string) {
        return axiosInstance.post("/events/message", {content: msg}).then((res) => {
            // @ts-ignore
            console.log(res.data)
            return res.data;
        })
    }
}
