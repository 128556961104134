import {Component, Fragment, JSX} from "react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {Button, Image, Modal, Radio, Rate, Slider, Stepper, Toast} from "antd-mobile";
import './index.less'
import PropertyItem from "../../../component/PropertyItem";
import EquipItem from "../../../component/EquipItem";
import SkillItem from "../../../component/SkillItem";
import {inject, observer} from "mobx-react";
import {IStore} from "../../../stores";
import {GetItemType, ItemTopType, ItemType, ItemTypeList} from "../../../enums/ItemType";
import {PlayerItemEntity} from "../../../models/PlayerItemEntity";
import {APIHelper} from "../../../utlis/APIHelper";
import PropertyEntity from "../../../models/PropertyEntity";
import {GetAssetIcon, GetAssetIconByIcon} from "../../../utlis/AssetsUtils";
import {toJS} from "mobx";
import PlayerSkillEntity from "../../../models/PlayerSkillEntity";
import {ArrowRightOutlined} from "@ant-design/icons";
import EquipProperty from "../../../component/EquipProperty";
import {GetSchoolByWuxing} from "../../../enums/WuXing";
import {ModalUtils} from "../../../utlis/modalUtils";
import ProductItem from "../../../component/ProductItem";
import BackpackItem from "../../../component/BackpackItem";
import {ArchiveEntity} from "../../../models/ArchiveEntity";
import ArchiveItem from "../../../component/ArchiveItem";

interface ICharacterProps extends IRouterProps, IStore {

}

@inject("player", "config", "tabs")
@observer
class Character extends Component<ICharacterProps, any> {
    constructor(props: any) {
        super(props);
    }

    async onAbilityPointAdd(type: "1" | "2" | "3") {
        let amount = 1;
        Modal.show({
            bodyClassName: "ability-modal",
            closeOnMaskClick: true,
            header: null,
            content: <div style={{height: "100%"}}>
                <div className="top-content">
                    <div>{type === "1" ? "力量" : type === "2" ? "体制" : "敏捷"}强化</div>
                    <Stepper style={{
                        "--input-background-color": "#2C4F62",
                        "--input-font-color": "white",
                        "--button-background-color": "#2C4F62",
                        "--button-text-color": "white",
                        margin: "0.3rem 0"
                    }} step={1} min={1} defaultValue={amount} onChange={(v) => amount = v}
                             max={this.props.player.currentPlayer.abilityPoint}></Stepper>
                    <div>{`最大值:${this.props.player.currentPlayer.abilityPoint},最小值:1`}</div>
                </div>

                <div style={{
                    height: "0.8rem",
                    position: "absolute",
                    bottom: "3px",
                    width: "5.28rem",
                    display: "flex",
                    boxSizing: "border-box",
                }}>
                    <a onClick={async () => {
                        ModalUtils.ShowLoading();
                        await APIHelper.UpdatePlayerAbilityPoint(type, amount)
                        await this.props.player.refreshPlayerInfo();
                        ModalUtils.HideLoading();
                    }
                    } style={{
                        flex: 1,
                        borderTop: "solid 1px #787332",
                        color: "white",
                        borderRight: "solid 1px #787332",
                        fontSize: "0.36rem",
                        fontWeight: "600",
                        textAlign: "center",
                        lineHeight: "0.8rem"
                    }}>确定</a>
                    <a onClick={Modal.clear} style={{
                        flex: 1,
                        borderTop: "solid 1px #787332",
                        color: "white",
                        fontSize: "0.36rem",
                        fontWeight: "600",
                        textAlign: "center",
                        lineHeight: "0.8rem"
                    }}>关闭</a>
                </div>
            </div>
        })
    }

    renderProperty() {
        const {character} = this.props.tabs;
        if (character.currentTab !== "property") {
            return null;
        }
        const {currentServer} = this.props.config;
        const {currentPlayer, equipmentProperty} = this.props.player;
        return <div className="details-container">
            <div>
                <div className="property-row">
                    <PropertyItem title="服务器" value={currentServer.serverName}></PropertyItem>
                    <PropertyItem title="ID" value="1"></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="门派" value={GetSchoolByWuxing(currentPlayer.wuxing)}></PropertyItem>
                    <PropertyItem title="战力荣誉" value={currentPlayer.equipmentPoint}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="门派荣誉" value={currentPlayer.schoolPoint}></PropertyItem>
                    <PropertyItem title="领土荣誉" value={currentPlayer.territoryPoint}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="逍遥荣誉" value={currentPlayer.freePoint}></PropertyItem>
                    <PropertyItem title="宋金荣誉" value={currentPlayer.schoolPoint}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="累计经验"
                                  value={currentPlayer.exp - currentPlayer.lastLevelExp}></PropertyItem>
                    <div className="property-item" style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{color: "#F6C509"}}>下一级</div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <span>{currentPlayer.nextLevelExp - currentPlayer.lastLevelExp}</span>
                            {
                                (currentPlayer.exp - currentPlayer.lastLevelExp) >= (currentPlayer.nextLevelExp - currentPlayer.lastLevelExp)
                                && <div onClick={this.onPlayerUpgrade} style={{
                                    background: "#2C4F62",
                                    width: "0.72rem",
                                    height: "0.36rem",
                                    borderRadius: "0.28rem",
                                    fontSize: "0.24rem",
                                    lineHeight: "0.36rem",
                                    margin: "0.12rem 0.1rem"
                                }}>升级</div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="property-row">
                    <PropertyItem title="攻击"
                                  value={currentPlayer.property.attack + equipmentProperty.attack}></PropertyItem>
                    <PropertyItem title="潜力点数"
                                  value={currentPlayer.abilityPoint}></PropertyItem>
                </div>
                <div className="property-row">

                    <PropertyItem title="速度"
                                  value={currentPlayer.property.speed + equipmentProperty.speed}></PropertyItem>
                    <PropertyItem title="力量" value={currentPlayer.strength} addAble={currentPlayer.abilityPoint > 0}
                                  onAdd={() => {
                                      this.onAbilityPointAdd("1");
                                  }}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="生命"
                                  value={currentPlayer.property.health + equipmentProperty.health}></PropertyItem>
                    <PropertyItem title="体质" value={currentPlayer.physique} addAble={currentPlayer.abilityPoint > 0}
                                  onAdd={() => {
                                      this.onAbilityPointAdd("2");
                                  }}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="防御"
                                  value={currentPlayer.property.defense + equipmentProperty.defense}></PropertyItem>
                    <PropertyItem title="敏捷" value={currentPlayer.agility} addAble={currentPlayer.abilityPoint > 0}
                                  onAdd={() => {
                                      this.onAbilityPointAdd("3");
                                  }}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="命中"
                                  value={currentPlayer.property.hitRate + equipmentProperty.hitRate}></PropertyItem>
                    <PropertyItem title="闪避"
                                  value={currentPlayer.property.dodgeRate + equipmentProperty.dodgeRate}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="会心一击"
                                  value={currentPlayer.property.criticalRate + equipmentProperty.criticalRate}></PropertyItem>
                    <PropertyItem title="会心伤害"
                                  value={currentPlayer.property.criticalDamage + equipmentProperty.criticalDamage}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="五行增益"
                                  value={currentPlayer.property.wuxingPositive + equipmentProperty.wuxingPositive}></PropertyItem>
                    <PropertyItem title="五行减益"
                                  value={currentPlayer.property.wuxingNegative + equipmentProperty.wuxingNegative}></PropertyItem>
                </div>
                <div className="property-row">
                    <PropertyItem title="伤害放大"
                                  value={currentPlayer.property.damageBoost + equipmentProperty.damageBoost}></PropertyItem>
                    <PropertyItem title="伤害缩小"
                                  value={currentPlayer.property.defenseBoost + equipmentProperty.defenseBoost}></PropertyItem>
                </div>
            </div>
        </div>
    }


    onConfirmUpgrade(item: PlayerItemEntity) {
        ModalUtils.ShowLoading();
        APIHelper.UpgradeItem(item.id).then(() => {
            return this.props.player.refreshPlayerItems()
        }).then(() => {
            return this.props.player.refreshPlayerInfo()
        }).then(() => {
            ModalUtils.ShowSuccessMsg("强化成功");
            Modal.clear();
            this.onEquipUpgradeClick(this.props.player.userPlayerItems.find((i) => i.id === item.id))
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);
        }).finally(() => {
            ModalUtils.HideLoading();
        });
    }

    onPlayerUpgrade = () => {
        ModalUtils.ShowConfirm("提示", "确认要升级吗?", () => {
            ModalUtils.ShowLoading();
            APIHelper.PlayerTryUpgrade().then(() => {
                return this.props.player.refreshPlayerInfo()
            }).then(() => {
                ModalUtils.ShowSuccessMsg("升级成功");
            }).catch((err) => {
                ModalUtils.ShowFailedMsg(err);
            }).finally(() => {
                ModalUtils.HideLoading();
            });
        })
    }


    renderItemUpgradeProperty(color, type, origin: PropertyEntity, upgrade: PropertyEntity, scale) {
        var itemType = type as ItemType;

        return <div style={{fontSize: "0.24rem"}}>
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Necklace || itemType === ItemType.Ring || itemType === ItemType.JadePendant || itemType === ItemType.Talisman) &&
                <EquipProperty color={color} title="攻击力" value={origin.attack + upgrade.attack}
                               toValue={Math.floor(origin.attack * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.Helmet || itemType === ItemType.Cloth || itemType === ItemType.Belt || itemType === ItemType.Bracers || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="防御力" value={origin.defense + upgrade.defense}
                               toValue={Math.floor(origin.defense * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.Helmet || itemType === ItemType.Cloth || itemType === ItemType.Belt || itemType === ItemType.Bracers || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="生命值" value={origin.health + upgrade.health}
                               toValue={Math.floor(origin.health * scale)}></EquipProperty>

            }
            {
                (itemType === ItemType.Weapon) &&
                <EquipProperty color={color} title="命中率" value={origin.hitRate + upgrade.hitRate}
                               toValue={Math.floor(origin.hitRate * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.JadePendant) &&
                <EquipProperty color={color} title="闪避率" value={origin.dodgeRate + upgrade.dodgeRate}
                               toValue={Math.floor(origin.dodgeRate * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Necklace) &&
                <EquipProperty color={color} title="会心率" value={origin.criticalRate + upgrade.criticalRate}
                               toValue={Math.floor(origin.criticalRate * scale)}></EquipProperty>

            }
            {
                (itemType === ItemType.Talisman) &&
                <EquipProperty color={color} title="会伤" value={origin.criticalDamage + upgrade.criticalDamage}
                               toValue={Math.floor(origin.criticalDamage * scale)}></EquipProperty>

            }
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="速度" value={origin.speed + upgrade.speed}
                               toValue={Math.floor(origin.speed * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.Ring) &&
                <EquipProperty color={color} title="伤害放大" value={origin.damageBoost + upgrade.damageBoost}
                               toValue={Math.floor(origin.damageBoost * scale)}></EquipProperty>

            }
            {
                (itemType === ItemType.Cloth) &&
                <EquipProperty color={color} title="伤害缩小" value={origin.defenseBoost + upgrade.defenseBoost}
                               toValue={Math.floor(origin.defenseBoost * scale)}></EquipProperty>
            }
        </div>
    }

    renderItemProperty(color, type, origin: PropertyEntity, upgrade: PropertyEntity) {
        var itemType = type as ItemType;
        return <div style={{fontSize: "0.24rem"}}>
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Necklace || itemType === ItemType.Ring || itemType === ItemType.JadePendant || itemType === ItemType.Talisman) &&
                <EquipProperty color={color} title="攻击力" value={origin.attack + upgrade.attack}></EquipProperty>
            }
            {
                (itemType === ItemType.Helmet || itemType === ItemType.Cloth || itemType === ItemType.Belt || itemType === ItemType.Bracers || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="防御力" value={origin.defense + upgrade.defense}></EquipProperty>
            }
            {
                (itemType === ItemType.Helmet || itemType === ItemType.Cloth || itemType === ItemType.Belt || itemType === ItemType.Bracers || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="生命值" value={origin.health + upgrade.health}></EquipProperty>

            }
            {
                (itemType === ItemType.Weapon) &&
                <EquipProperty color={color} title="命中率" value={origin.hitRate + upgrade.hitRate}></EquipProperty>
            }
            {
                (itemType === ItemType.JadePendant) &&
                <EquipProperty color={color} title="闪避率"
                               value={origin.dodgeRate + upgrade.dodgeRate}></EquipProperty>
            }
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Necklace) &&
                <EquipProperty color={color} title="会心率"
                               value={origin.criticalRate + upgrade.criticalRate}></EquipProperty>

            }
            {
                (itemType === ItemType.Talisman) &&
                <EquipProperty color={color} title="会伤"
                               value={origin.criticalDamage + upgrade.criticalDamage}></EquipProperty>

            }
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="速度" value={origin.speed + upgrade.speed}></EquipProperty>
            }
            {
                (itemType === ItemType.Ring) &&
                <EquipProperty color={color} title="伤害放大"
                               value={origin.damageBoost + upgrade.damageBoost}></EquipProperty>

            }
            {
                (itemType === ItemType.Cloth) &&
                <EquipProperty color={color} title="伤害缩小"
                               value={origin.defenseBoost + upgrade.defenseBoost}></EquipProperty>
            }
        </div>
    }


    onEquipUpgradeClick(item: PlayerItemEntity) {
        var color = item.rarity === 1 ? "#00EEFF" : (item.rarity === 2 ? "#C800FF" : "#F6C509")
        ModalUtils.ShowLoading();
        let upgradeAble = GetItemType(item.item.type) === ItemTopType.AttackWeapon || GetItemType(item.item.type) === ItemTopType.DefenseWeapon;
        APIHelper.GetItemUpgradeInfo(item.id).then((res) => {
            ModalUtils.HideLoading();
            const config = res.data.config;
            upgradeAble = upgradeAble && config;
            var xuanjing = config ? this.props.config.itemTemplateMap.get(config.item + "") : null;
            Modal.show({
                bodyClassName: upgradeAble ? "upgrade-modal" : "info-modal",
                closeOnMaskClick: true,
                header: null,
                content: <div style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden"
                }}>
                    <div className="top-content">
                        <div style={{display: "flex"}}>
                            <div style={{
                                width: "1rem",
                                height: "1rem",
                                padding: "0.05rem",
                                marginBottom: "0.1rem",
                                boxSizing: "border-box",
                                position: "relative",
                                marginRight: "0.1rem",
                                border: `solid 0.05rem ${color}`
                            }}>
                                <img src={GetAssetIconByIcon(item.item.type, item.item.icon, item.item.wuxing)}
                                     style={{width: "0.8rem", height: "0.8rem"}}/>
                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    fontSize: "0.24rem"
                                }}>+{item.level}</div>
                            </div>
                            <div>
                                {
                                    <div style={{
                                        color: "white",
                                        fontSize: "0.28rem",
                                        height: "0.36rem",
                                        lineHeight: "0.36rem",
                                        marginTop: "0.1rem"
                                    }}>{upgradeAble ? (item.item.name + " +" + item.level) : (item.item.name)}
                                    </div>
                                }
                                {
                                    upgradeAble && <div style={{color: "#FBFF00", fontSize: "0.36rem"}}>强化至
                                        <span
                                            style={{
                                                color: "#F6C509",
                                                fontSize: "0.36rem",
                                                marginLeft: "0.1rem",
                                                lineHeight: "0.36rem"
                                            }}>{config.level - 1}</span><ArrowRightOutlined
                                            style={{width: "0.24rem", height: "0.24rem", margin: "0.1rem 0"}}/><span
                                            style={{
                                                color: "#F6C509",
                                                fontSize: "0.36rem",
                                                lineHeight: "0.36rem"
                                            }}>{config.level}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <Rate style={{"--star-size": "0.24rem"}} count={8} value={2}></Rate>
                        {
                            upgradeAble &&
                            <Fragment>
                                {
                                    config && <div>
                                        <div style={{marginTop: "0.1rem"}}>玄晶强化</div>
                                        <div style={{marginTop: "0.1rem", display: "flex"}}>
                                            {
                                                <div style={{
                                                    width: "1rem",
                                                    height: "1rem",

                                                    padding: "0.05rem",
                                                    boxSizing: "border-box",
                                                    position: "relative",
                                                    border: `solid 0.05rem #00EEFF`
                                                }}>
                                                    <Image
                                                        src={GetAssetIconByIcon(xuanjing.type, xuanjing.icon, xuanjing.wuxing)}
                                                        style={{width: "100%", height: "100%"}}/>
                                                </div>
                                            }
                                            {
                                                <div style={{
                                                    width: "1rem",
                                                    height: "1rem",
                                                    padding: "0.05rem",
                                                    boxSizing: "border-box",
                                                    marginLeft: "0.1rem",
                                                    position: "relative",
                                                    border: `solid 0.05rem #00EEFF`
                                                }}>
                                                    <Image src="/images/ui/coins_icon.png"
                                                           style={{width: "100%", height: "100%"}}/>
                                                </div>
                                            }
                                        </div>
                                        <div style={{marginTop: "0.1rem", fontSize: "0.28rem"}}>消耗资源</div>
                                        <div style={{
                                            marginTop: "0.1rem",
                                            fontSize: "0.28rem"
                                        }}>{xuanjing.name}*{config.itemAmount}</div>
                                        <div style={{
                                            marginTop: "0.1rem",
                                            color: "#F6C509",
                                            fontSize: "0.28rem"
                                        }}>银钱*{config.coins}</div>
                                    </div>
                                }
                            </Fragment>}
                        <div style={{marginTop: "0.1rem"}}>基础属性</div>
                        {
                            config ? this.renderItemUpgradeProperty(color, item.item.type, res.data.result.property, res.data.result.upgradeProperty, config.property / 1000) :
                                this.renderItemProperty(color, item.item.type, res.data.result.property, res.data.result.upgradeProperty)
                        }
                        {this.renderEquipLevelLockProperty(item)}
                    </div>
                    <div style={{
                        height: "0.8rem",
                        width: "5.28rem",
                        display: "flex",
                        boxSizing: "border-box",
                        color
                    }}>
                        {
                            item.level < 16 && (GetItemType(item.item.type) === ItemTopType.AttackWeapon || GetItemType(item.item.type) === ItemTopType.DefenseWeapon) &&
                            <a onClick={() => {
                                this.onConfirmUpgrade(item)
                            }
                            } style={{
                                flex: 1,
                                borderTop: "solid 1px #787332",
                                color: "white",
                                borderRight: "solid 1px #787332",
                                fontSize: "0.36rem",
                                fontWeight: "600",
                                textAlign: "center",
                                lineHeight: "0.8rem"
                            }}>强化</a>
                        }
                        <a onClick={Modal.clear} style={{
                            flex: 1,
                            borderTop: "solid 1px #787332",
                            color: "white",
                            fontSize: "0.36rem",
                            fontWeight: "600",
                            textAlign: "center",
                            lineHeight: "0.8rem"
                        }}>关闭</a>
                    </div>
                </div>
            })
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);
        })

    }

    renderEquipLevelLockProperty(item: PlayerItemEntity) {
        const itemTemp = this.props.config.itemTemplateMap.get(item.item.id);
        console.log(itemTemp);
        itemTemp.levels.sort((a, b) => a.unlockLevel - b.unlockLevel);
        if (!itemTemp.levels || itemTemp.levels.length == 0) {
            return null;
        }
        return <Fragment>
            <div style={{marginTop: "0.1rem"}}>强化({item.level}/16)</div>
            {
                itemTemp.levels.map((level) => {
                    let property = "";
                    if (level.property.attack > 0) {
                        property += `攻击+${level.property.attack},`;
                    }
                    if (level.property.health > 0) {
                        property += `生命+${level.property.health},`;

                    }
                    if (level.property.defense > 0) {
                        property += `防御+${level.property.defense},`;

                    }
                    if (level.property.hitRate > 0) {
                        property += `命中+${level.property.hitRate},`;

                    }
                    if (level.property.dodgeRate > 0) {
                        property += `闪避+${level.property.dodgeRate},`;

                    }
                    if (level.property.speed > 0) {
                        property += `速度+${level.property.speed},`;

                    }
                    if (level.property.criticalDamage > 0) {
                        property += `会伤+${level.property.criticalDamage},`;

                    }
                    if (level.property.criticalRate > 0) {
                        property += `会心+${level.property.criticalRate},`;

                    }
                    if (level.property.damageBoost > 0) {
                        property += `伤害增强+${level.property.damageBoost},`;

                    }
                    if (level.property.defenseBoost > 0) {
                        property += `防御增强+${level.property.defenseBoost},`;

                    }
                    if (level.property.wuxingPositive > 0) {
                        property += `五行增益+${level.property.wuxingPositive},`;

                    }
                    if (level.property.wuxingNegative > 0) {
                        property += `五行减益+${level.property.wuxingNegative},`;
                    }
                    if (property.length>0){
                        property = property.substring(0,property.length -1);
                    }
                    else {
                        return null;
                    }
                    return <div style={{display: "flex", fontSize: "0.24rem"}}>
                        <div
                            style={{color: item.level >= level.unlockLevel ? "#FBFF00" : "#00FF00"}}>(+{level.unlockLevel})
                        </div>
                        <div style={{color: item.level >= level.unlockLevel ? "#FBFF00" : "#00FF00"}}>{property}</div>
                    </div>
                })
            }
        </Fragment>
    }

    onUnLoadEquip(item: PlayerItemEntity) {
        ModalUtils.ShowLoading();
        APIHelper.UnloadItem({itemId: item.id}).then(() => {
            return this.props.player.refreshPlayerItems()
        }).then(() => {
            return this.props.player.refreshPlayerInfo()
        }).then(() => {
            ModalUtils.ShowSuccessMsg("卸下成功");
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);

        }).finally(() => {
            ModalUtils.HideLoading();
        });

    }

    renderEquipment() {
        if (this.props.tabs.character.currentTab !== "equipment") {
            return null;
        }
        var {equippedItems, equipmentProperty} = this.props.player;
        return <div className="details-container">
            <div className="equip-row">
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Helmet.valueOf())} type={ItemType.Helmet}></EquipItem>
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           item={equippedItems.get(ItemType.Weapon.valueOf())}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           wuxing={this.props.player.currentPlayer.wuxing} type={ItemType.Weapon}></EquipItem>
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Cloak.valueOf())} type={ItemType.Cloak}></EquipItem>

            </div>
            <div className="equip-row">
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Cloth.valueOf())} type={ItemType.Cloth}></EquipItem>
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Necklace.valueOf())} type={ItemType.Necklace}></EquipItem>
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.WuxingSheet.valueOf())}
                           type={ItemType.WuxingSheet}></EquipItem>

            </div>
            <div className="equip-row">
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Belt.valueOf())} type={ItemType.Belt}></EquipItem>
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Ring.valueOf())} type={ItemType.Ring}></EquipItem>
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.LevelSheet.valueOf())}
                           type={ItemType.LevelSheet}></EquipItem>
            </div>
            <div className="equip-row">
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Bracers.valueOf())} type={ItemType.Bracers}></EquipItem>
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.JadePendant.valueOf())}
                           type={ItemType.JadePendant}></EquipItem>
                <div style={{display: "flex", width: "1.35rem", margin: "0 0.1rem 0.12rem 0.1rem"}}></div>
            </div>
            <div className="equip-row">
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Shoe.valueOf())} type={ItemType.Shoe}></EquipItem>
                <EquipItem onClick={this.onEquipUpgradeClick.bind(this)}
                           onUnload={this.onUnLoadEquip.bind(this)}
                           item={equippedItems.get(ItemType.Talisman.valueOf())} type={ItemType.Talisman}></EquipItem>
                <div style={{display: "flex", width: "1.35rem", margin: "0 0.1rem 0.12rem 0.1rem"}}></div>
            </div>
            <div style={{display: "flex", height: "0.5rem"}}>
                <div className="spit-line"></div>
                <div style={{width: "3rem", lineHeight: "0.5rem"}}>装备属性</div>
                <div className="spit-line"></div>
            </div>
            <div className="equip-property-row">
                <div className="equip-property-item">攻击:{equipmentProperty.attack}</div>
                <div className="equip-property-item">防御:{equipmentProperty.defense}</div>
            </div>
            <div className="equip-property-row">
                <div className="equip-property-item">生命:{equipmentProperty.health}</div>
                <div className="equip-property-item">速度:{equipmentProperty.speed}</div>
            </div>
            <div className="equip-property-row">
                <div className="equip-property-item">命中:{equipmentProperty.hitRate}</div>
                <div className="equip-property-item">闪避:{equipmentProperty.dodgeRate}</div>
            </div>
            <div className="equip-property-row">
                <div className="equip-property-item">会心:{equipmentProperty.criticalRate}</div>
                <div className="equip-property-item">会伤:{equipmentProperty.criticalDamage}</div>
            </div>
            <div className="equip-property-row">
                <div className="equip-property-item">五行增益:{equipmentProperty.wuxingPositive}</div>
                <div className="equip-property-item">五行减益:{equipmentProperty.wuxingNegative}</div>
            </div>
            <div className="equip-property-row">
                <div className="equip-property-item">伤害放大:{equipmentProperty.damageBoost}</div>
                <div className="equip-property-item">伤害缩小:{equipmentProperty.defenseBoost}</div>
            </div>
        </div>
    }


    viewSkillDetails(playerSkill: PlayerSkillEntity) {
        console.log(toJS(this.props.config.gameSkillMap.get(playerSkill.skill.id)))
        const skill = this.props.config.gameSkillMap.get(playerSkill.skill.id);
        var effects: Array<JSX.Element> = [];
        for (let i = 0; i < skill.activeEffects.length; i++) {
            var effect = skill.activeEffects[i];
            switch (effect.attribute) {
                case "damage":
                    effects.push(
                        <div>{`对敌人造成${skill.effectTimes}次伤害，每次造成${skill.activeEffects[0].amount}%的攻击伤害`}</div>)
                    break;
                case "burningBuffer":
                    effects.push(
                        <div>{`攻击时，有${effect.amount}%机率对敌方施加一个燃烧的buff,持续${effect.duration}个回会，buff持续期间敌方受到的伤害增加`}</div>)
                    break;
                case "dodgeBuffer":
                    effects.push(
                        <div>{`攻击时，有${effect.amount}%机率对敌方施加一个眩晕的buff,持续${effect.duration}个回会，buff持续期间敌方无法行动`}</div>)
                    break;
                case "injuryBuffer":
                    effects.push(
                        <div>{`攻击时，有${effect.amount}%机率对敌方施加一个受伤的buff,持续${effect.duration}个回会，buff持续期间敌方防御降低20%`}</div>)
                    break;
                case "noAttackBuffer":
                    effects.push(
                        <div>{`攻击时，有${effect.amount}%机率对自己施加一个不攻的buff,持续${effect.duration}个回会，buff持续期间自己的防御提升20%`}</div>)
                    break;
                case "weaknessBuffer":
                    effects.push(
                        <div>{`攻击时，有${effect.amount}%机率对敌方施加一个虚弱的buff,持续${effect.duration}个回会，buff持续期间敌方攻击力降低20%`}</div>)
                    break;
            }
        }
        for (let i = 0; i < skill.passiveEffects.length; i++) {
            var effect = skill.passiveEffects[i];
            switch (effect.attribute) {
                case "attack":
                    effects.push(
                        <div>{`攻击:+${effect.amount}`}</div>)
                    break;
                case "criticalRate":
                    effects.push(
                        <div>{`会心:+${effect.amount}`}</div>)
                    break;
                case "criticalDamage":
                    effects.push(
                        <div>{`会伤:+${effect.amount}`}</div>)
                    break;
                case "defense":
                    effects.push(
                        <div>{`防御:+${effect.amount}`}</div>)
                    break;
                case "dodgeBuffer":
                    effects.push(
                        <div>{`回合中血量低于50%时触发闪避+${effect.amount}，持续2回合(每场战斗只触发一次)`}</div>)
                    break;
                case "dodgeRate":
                    effects.push(
                        <div>{`眩晕几率:+${effect.amount}`}</div>)
                    break;
                case "health":
                    effects.push(
                        <div>{`生命:+${effect.amount}`}</div>)
                    break;
                case "hitRate":
                    effects.push(
                        <div>{`命中:+${effect.amount}`}</div>)
                    break;
                case "skillRate":
                    effects.push(
                        <div>{`基础几率:+${effect.amount}`}</div>)
                    break;
                case "unDieBuffer":
                    effects.push(
                        <div>{`50%概率免疫死亡且不受控制一回合，持续1回合(每场战斗只触发一次)`}</div>)
                    break;
            }
        }
        Modal.show({
            bodyClassName: "dungeons-level-modal",
            closeOnMaskClick: true,
            header: null,
            className: "dungeons-modal",
            title: `${skill.name}(${skill.type === 1 ? "主动" : "被动"})`,
            showCloseButton: true,
            content: <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "0.12rem",
                boxSizing: "border-box",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {
                    effects.map((ele) => ele)
                }
            </div>
        })
    }

    renderSkill() {
        if (this.props.tabs.character.currentTab !== "skill") {
            return null;
        }

        const skills = this.props.player.currentPlayer.playerSkills;
        return <div className="details-container">
            {
                skills.map((item, index) => {
                    return <SkillItem onClick={(data) => this.viewSkillDetails(data)} key={index}
                                      data={item}></SkillItem>
                })
            }
        </div>
    }

    renderShenqi() {
        if (this.props.tabs.character.currentTab !== "shenqi") {
            return null;
        }
        return <div className="details-container">

        </div>
    }

    render() {
        const {character} = this.props.tabs;

        return <div className="character-details-container">
            <div className="cha-tabs">
                <Button onClick={() => character.UpdateCurrentTab("property")}
                        className={`cha-tab-item ${character.currentTab === "property" ? "active" : ""}`}
                        key="property">属性</Button>
                <Button onClick={() => character.UpdateCurrentTab("equipment")}
                        className={`cha-tab-item ${character.currentTab === "equipment" ? "active" : ""}`}
                        key="equipment">装备</Button>
                <Button onClick={() => character.UpdateCurrentTab("skill")}
                        className={`cha-tab-item ${character.currentTab === "skill" ? "active" : ""}`}
                        key="skill">技能</Button>
                <Button onClick={() => character.UpdateCurrentTab("archives")}
                        className={`cha-tab-item ${character.currentTab === "archives" ? "active" : ""}`}
                        key="archives">称号</Button>
                <Button onClick={() => character.UpdateCurrentTab("market")}
                        className={`cha-tab-item ${character.currentTab === "market" ? "active" : ""}`}
                        key="market">商城</Button>
            </div>
            {
                this.renderProperty()
            }
            {
                this.renderEquipment()
            }
            {
                this.renderSkill()
            }
            {
                this.renderShenqi()
            }
            {
                this.renderArchives()
            }
            {
                this.renderStore()
            }
        </div>;
    }


    private renderArchives() {
        if (this.props.tabs.character.currentTab !== "archives") {
            return null;
        }

        const {config} = this.props;
        const itemTypes = ItemTypeList();
        return <div style={{display: "flex", flexDirection: "column", overflow: "hidden", alignItems: "center"}}>
            <div className="archive-filter">
                <div className="filter-title">类型</div>
                <Radio.Group value={this.props.tabs.character.archiveFilter}
                             onChange={(v) => this.props.tabs.character.UpdateArchiveFilter(parseInt(v + ""))}>
                    <Radio value={0} key="all">全部</Radio>
                    <Radio value={1} key="0">战力称号</Radio>
                    <Radio value={2} key="1">领土称号</Radio>
                    <Radio value={3} key="2">门派称号</Radio>
                </Radio.Group>
            </div>
            <div className="item-list">
                {
                    config.archives.map((item: ArchiveEntity) => {
                        if (this.props.tabs.character.archiveFilter !== 0 && this.props.tabs.character.archiveFilter !== item.type) {
                            return null;
                        }
                        return <ArchiveItem onClick={() => {
                        }} data={item}
                                            key={item.id}></ArchiveItem>
                    })
                }
            </div>
        </div>
    }

    private renderStore() {
        if (this.props.tabs.character.currentTab !== "market") {
            return null;
        }

        const products = this.props.config.products;
        return <div className="details-container">
            {
                products.map((item, index) => {
                    return <ProductItem onWxBuy={async (data) => {
                        window.location.href = (await APIHelper.CreatePayUrl(data.sku, "wxpay")).data
                    }} onZfbBuy={async (data) => {
                        window.location.href = (await APIHelper.CreatePayUrl(data.sku, "alipay")).data
                    }} key={index}
                                        data={item}></ProductItem>
                })
            }
        </div>
    }
}

export default withRouter(Character);
